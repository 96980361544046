<template>
  <div class="warp">
    <!-- 返回按钮 -->
    <div class="back" @click="$router.go(-1)">
      <svg-icon icon-class="back"></svg-icon>
    </div>
    <div class="my-swipe-box">
      <v-touch @swipeleft="swipeLeft" @swiperight="swipeRight" class="touch" @swipedown="swiperdown">
        <swiper ref="swiper" :options="swiperOption" class="my-swipe">
          <swiper-slide class="swiperSlide" v-for="(item,index) in imgs" :key="index">
            <ImgDecypt class="tag-img" :src="item" fill="cover"></ImgDecypt>
          </swiper-slide>
        </swiper>
      </v-touch>
      <div class="my_swiper_pagination">{{ this.current + 1 + "/" + this.imgs.length }}</div>

    </div>
    <!--金币购买弹窗-->
    <GoldBuy class="buy_parent" :video-info="postInfo" :show="isGoldbuyShow"
             @close="isGoldbuyShow=false" :isVip="isVip"
             @succcess="succcess" title="本图集需要购买解锁"/>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt"
// import {Swipe, SwipeItem} from 'vant';
import GoldBuy from "@/components/GoldBuy";
import {mapGetters} from "vuex";
import "swiper/dist/css/swiper.css";
import {swiper, swiperSlide} from "vue-awesome-swiper";

export default {
  components: {
    ImgDecypt,
    swiper,
    swiperSlide,
    // [Swipe.name]: Swipe,
    // [SwipeItem.name]: SwipeItem,
    GoldBuy
  },
  name: "PicturePreview",
  data() {
    return {
      text: require("@/assets/png/ad.png"),
      current: 0,
      imgs: [],
      isGoldbuyShow: false,
      scale: 1,
      // 轮播图配置
      swiperOption: {
        loop: false,
        allowTouchMove: false,
        zoom: {
          containerClass: 'swiper-zoom-container',
          passiveListeners: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        grabCursor: true,
        onlyExternal: true,
        autoplay: false,
        on: {
          slideChangeTransitionStart: () => {
            this.current = this.swiper.activeIndex;
          },
          zoomChange: (scale) => {
            this.scale = scale;
          }
        },

      },
      postInfo: {}
    };
  },
  computed: {
    ...mapGetters({
      isVip: 'isVip'
    }),
    swiper() {
      return this.$refs.swiper.swiper
    }
  },
  created() {
    this.imgs = this.$route.params.imgs;
    this.current = this.$route.params.index || 0;
    this.$nextTick(() => {
      this.swiper.slideTo(this.current, true);
    })
    this.postInfo = this.$route.params.info;
    this.$store.dispatch("user/getWallet");
  },
  activated() {
    this.$store.dispatch("user/getWallet");
  },
  methods: {
    swipeLeft() {
      if (this.scale != 1) return;
      let price = this.isVip ? this.postInfo?.coins : this.postInfo?.originCoins;
      let uInfo = this.$store.getters.userInfo;
      let currentUid = this.$route.params.uid;
      if (this.current > 4 && !this.isBuy() && price > 0 && uInfo.uid != currentUid) {
        this.isGoldbuyShow = true
        return
      }
      this.swiper.slideNext();
    },
    succcess() {
      this.postInfo.vidStatus.hasPaid = true;
      this.isGoldbuyShow = false
    },
    isBuy() {
      return this.postInfo?.vidStatus?.hasPaid || false
    },
    swipeRight() {
      if (this.scale != 1) return;
      this.swiper.slidePrev();
    },
    swiperdown() {
      if (this.scale != 1) return;
      this.$router.go(-1)

    }
  },
};
</script>
<style scoped lang="scss">
.warp {
  height: 100%;
  position: relative;

  .touch {
    height: 100%;
  }

  .back {
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 20;

    > svg {
      font-size: 24px;
    }
  }

  .my-swipe-box {
    height: 100%;
  }

  .my_swiper_pagination {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 111;
    color: #FFFFFF;
    padding: 2px 10px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.5);
  }

  .my-swipe {
    height: 100%;
    color: #fff;
    font-size: 0.4rem;
    text-align: center;
    background: #000000;
    position: relative;

    /deep/ .vanImage {
      display: flex;
      align-items: center;
      background: #000000 !important;
    }

    /deep/ .img-decypt {
      background: #000000 !important;
    }

    /deep/ .van-image__error {
      background: #000000 !important;
    }

    /deep/ img {
      height: auto !important;
      -webkit-touch-callout: default;
    }
  }

}


.tag-img {
  width: 100%;
  height: 100%;
}

</style>
